import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="shadowdancer"></a><h3>SHADOWDANCER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG195_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d8.</p>
    <a id="shadowdancer-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a shadowdancer, a
character must fulfill all the following criteria.</p>
    <p><b>Skills</b>: Move Silently 8 ranks, Hide 10 ranks, Perform
(dance) 5 ranks.</p>
    <p><b>Feats</b>: Combat Reflexes, Dodge, Mobility. </p>
    <h6>Class Skills</h6>
    <p className="initial">The shadowdancer&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#balance">Balance</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#bluff">Bluff</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#decipher-script">Decipher Script</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#disguise">Disguise</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#escape-artist">Escape
Artist</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#move-silently">Move
Silently</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#perform">Perform</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a>
(Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sleight-of-hand">Sleight
of Hand</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#tumble">Tumble</a>
(Dex), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#use-rope">Use Rope</a> (Dex).</p>
    <p><b>Skill Points at Each Level</b>: 6 + Int modifier.</p>
    <a id="table-the-shadowdancer"></a><p><b>Table: The Shadowdancer</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "8%"
          }}>Level</th>
          <th style={{
            "width": "13%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "8%"
          }}>Fort
Save</th>
          <th style={{
            "width": "8%"
          }}>Ref
Save</th>
          <th style={{
            "width": "8%"
          }}>Will
Save</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>Hide in plain sight</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+3</td>
          <td>+0</td>
          <td>Evasion, darkvision, uncanny dodge</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+3</td>
          <td>+1</td>
          <td>Shadow illusion, summon shadow</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1</td>
          <td>Shadow jump 20 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1</td>
          <td>Defensive roll, improved uncanny dodge</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>Shadow jump 40 ft., summon shadow</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>Slippery mind</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6</td>
          <td>+2</td>
          <td>+6</td>
          <td>+2</td>
          <td>Shadow jump 80 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6</td>
          <td>+3</td>
          <td>+6</td>
          <td>+3</td>
          <td>Summon shadow</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">Shadow jump 160 ft., improved evasion</td>
        </tr>
      </tbody>
    </table>
    <a id="shadowdancer-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are features of the
shadowdancer prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Shadowdancers are
proficient with the club, crossbow (hand, light, or heavy), dagger (any
type), dart, mace, morningstar, quarterstaff, rapier, sap, shortbow
(normal and composite), and short sword. Shadowdancers are proficient
with light armor but not with shields.</p>
    <a id="shadowdancer-hide-in-plain-sight"></a><p><b>Hide in Plain Sight (Su)</b>: A shadowdancer can use the
Hide skill even while being observed. As long as she is within 10 feet
of some sort of shadow, a shadowdancer can hide herself from view in
the open without anything to actually hide behind. She cannot, however,
hide in her own shadow.</p>
    <a id="shadowdancer-evasion"></a><p><b>Evasion (Ex)</b>: At 2nd level, a shadowdancer gains
evasion. If exposed to any effect that normally allows her to attempt a
Reflex saving throw for half damage, she takes no damage with a
successful saving throw. The evasion ability can only be used if the
shadowdancer is wearing light armor or no armor.</p>
    <a id="shadowdancer-darkvision"></a><p><b>Darkvision (Su)</b>: At 2nd level, a shadowdancer can see
in the dark as though she were permanently under the effect of a
darkvision spell.</p>
    <a id="shadowdancer-uncanny-dodge"></a><p><b>Uncanny Dodge (Ex)</b>: Starting at 2nd level, a
shadowdancer retains her Dexterity bonus to AC (if any) regardless of
being caught flat-footed or struck by an invisible attacker. (She still
loses any Dexterity bonus to AC if immobilized.)</p>
    <p>If a character gains uncanny dodge from a second class, the
character automatically gains improved uncanny dodge (see below).</p>
    <a id="shadowdancer-shadow-illusion"></a><p><b>Shadow Illusion (Sp)</b>: When a shadowdancer reaches 3rd
level, she can create visual illusions. This ability&#8217;s effect is
identical to that of the arcane spell silent image and may be employed
once per day.</p>
    <a id="shadowdancer-summon-shadow"></a><p><b>Summon Shadow (Su)</b>: At 3rd level, a shadowdancer can
summon a shadow, an undead shade. Unlike a normal shadow, this shadow&#8217;s
alignment matches that of the shadowdancer, and the creature cannot
create spawn. The summoned shadow cannot be turned, rebuked, or
commanded by any third party. This shadow serves as a companion to the
shadowdancer and can communicate intelligibly with the shadowdancer.
Every third level gained by the shadowdancer adds +2 HD (and the
requisite base attack and base save bonus increases) to her shadow
companion.</p>
    <p>If a shadow companion is destroyed, or the shadowdancer
chooses to dismiss it, the shadowdancer must attempt a DC 15 Fortitude
save. If the saving throw fails, the shadowdancer loses 200 experience
points per shadowdancer level. A successful saving throw reduces the
loss by half, to 100 XP per prestige class level. The shadowdancer&#8217;s XP
total can never go below 0 as the result of a shadow&#8217;s dismissal or
destruction. A destroyed or dismissed shadow companion cannot be
replaced for 30 days.</p>
    <a id="shadowdancer-shadow-jump"></a><p><b>Shadow Jump (Su)</b>: At 4th level, a shadowdancer gains
the ability to travel between shadows as if by means of a dimension
door spell. The limitation is that the magical transport must begin and
end in an area with at least some shadow. A shadowdancer can jump up to
a total of 20 feet each day in this way; this may be a single jump of
20 feet or two jumps of 10 feet each. Every two levels higher than 4th,
the distance a shadowdancer can jump each day doubles (40 feet at 6th,
80 feet at 8th, and 160 feet at 10th). This amount can be split among
many jumps, but each one, no matter how small, counts as a 10-foot
increment.</p>
    <a id="shadowdancer-defensive-roll"></a><p><b>Defensive Roll (Ex)</b>: Starting at 5th level, once per
day, when a shadowdancer would be reduced to 0 hit points or less by
damage in combat (from a weapon or other blow, not a spell or special
ability), she can attempt to roll with the damage. She makes a Reflex
saving throw (DC = damage dealt) and, if successful, takes only half
damage from the blow. She must be aware of the attack and able to react
to it in order to execute her defensive roll. If she is in a situation
that would deny her any Dexterity bonus to AC, she can&#8217;t attempt a
defensive roll.</p>
    <a id="shadowdancer-improved-uncanny-dodge"></a><p><b>Improved Uncanny Dodge (Ex)</b>: At 5th level, a
shadowdancer can no longer be flanked. This defense denies rogues the
ability to use flank attacks to sneak attack the shadowdancer. The
exception to this defense is that a rogue at least four levels higher
than the shadowdancer can flank her (and thus sneak attack her).</p>
    <p>If a character gains uncanny dodge (see above) from a second
class the character automatically gains improved uncanny dodge, and the
levels from those classes stack to determine the minimum rogue level
required to flank the character.</p>
    <a id="shadowdancer-slippery-mind"></a><p><b>Slippery Mind (Ex)</b>: At 7th level, if a shadowdancer is
affected by an enchantment and fails her saving throw, 1 round later
she can attempt her saving throw again. She only gets this one extra
chance to succeed at her saving throw. If it fails as well, the spell&#8217;s
effects occur normally.</p>
    <a id="shadowdancer-improved-evasion"></a><p><b>Improved Evasion (Ex)</b>: This ability, gained at 10th
level, works like evasion (see above). A shadowdancer takes no damage
at all on successful saving throws against attacks that allow a Reflex
saving throw for half damage. What&#8217;s more, she takes only half damage
even if she fails her saving throw.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      